import React, { useState, useEffect } from "react";
import {
  getBeltCountBySeason,
  getBeltsList,
} from "./../../services/beltServices"; // Fonction API pour récupérer les données

const BeltCounts = () => {
  const [beltCounts, setBeltCounts] = useState([]);
  const [belts, setBelts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  // Fonction pour récupérer les décomptes depuis l'API
  const fetchData = async () => {
    try {
      const [beltCountData, beltsData] = await Promise.all([
        getBeltCountBySeason(),
        getBeltsList(),
      ]);
      setBeltCounts(beltCountData.belts_distribution); // Décomptes par ceinture
      setBelts(beltsData); // Informations sur les ceintures
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      console.log("Récupération des données terminée.");
    }
  };

  return (
    <div className="container mx-auto py-8">
      <h2 className="text-3xl font-extrabold text-center mt-2 font-montserrat uppercase">
        Répartition des ceintures
      </h2>
      {beltCounts.length > 0 && belts.length > 0 && (
        <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4 mt-6">
          {beltCounts.map(({ belt_id, count }) => {
            // Trouver les données de la ceinture correspondante
            const belt = belts.find((b) => b.id === parseInt(belt_id));
            const beltUrl = belt
              ? `/img/belt/${belt.belt_url}`
              : "https://via.placeholder.com/50";
            const beltName = belt ? belt.nom : `Ceinture ${belt_id}`;

            return (
              <div
                key={belt_id}
                className="flex flex-col items-center justify-center p-2 border rounded-lg bg-gray-100 shadow-sm"
              >
                <img
                  src={beltUrl} // Chemin dynamique basé sur belt_url
                  alt={beltName}
                  className="object-cover w-sm h-sm rounded-t-lg cursor-pointer"
                />
                <p className="text-sm font-medium">{beltName}</p>{" "}
                {/* Nom dynamique */}
                <p className="text-lg font-bold text-gray-800">{count}</p>{" "}
                {/* Décompte */}
              </div>
            );
          })}
        </div>
      )}

      {beltCounts.length === 0 && (
        <p className="text-center text-gray-600">Aucun décompte trouvé.</p>
      )}
    </div>
  );
};

export default BeltCounts;
