import React, { useState, useEffect } from "react";
import { getAllAttendances } from "./../../../services/attendanceServices"; // Importer la fonction API
import { getGroupsList } from "./../../../services/groupServices"; // Importer la liste des groupes
import { getAdherentsByGroup } from "./../../../services/adherentServices"; // API pour récupérer les adhérents d'un groupe
import { getSettings } from "./../../../services/settingsServices"; // API pour récupérer la saison active
import { format } from "date-fns";
import Button from "../../../components/Button/Button";
import useScrollToTop from "../../../hooks/useScollToTop";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";

const AttendanceTable = () => {
  useScrollToTop();
  const [attendances, setAttendances] = useState([]); // Toutes les présences
  const [groups, setGroups] = useState([]);
  const [adherents, setAdherents] = useState([]);
  const [filterGroup, setFilterGroup] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seasonMonths, setSeasonMonths] = useState([]); // Liste des mois de la saison active
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const isAdmin = userRole === "admin";

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const groupData = await getGroupsList();
        setGroups(groupData.filter((group) => group.is_active));

        const attendanceData = await getAllAttendances();
        setAttendances(attendanceData);

        // Charger la saison active et générer les mois
        const settings = await getSettings();
        const activeSeason = settings?.[0]?.saison_active || "2024-2025";
        generateSeasonMonths(activeSeason);
      } catch (error) {
        console.error("Erreur lors du chargement des données :", error);
      }
    };

    fetchInitialData();
  }, []);

  const generateSeasonMonths = (season) => {
    const [startYear, endYear] = season.split("-").map(Number);
    const months = [
      ...Array.from({ length: 4 }, (_, i) => ({
        value: `${startYear}-${String(i + 9).padStart(2, "0")}`,
        label: `${new Date(startYear, i + 8).toLocaleString("fr-FR", {
          month: "long",
        })} ${startYear}`,
      })),
      ...Array.from({ length: 6 }, (_, i) => ({
        value: `${endYear}-${String(i + 1).padStart(2, "0")}`,
        label: `${new Date(endYear, i).toLocaleString("fr-FR", {
          month: "long",
        })} ${endYear}`,
      })),
    ];
    setSeasonMonths(months);
  };

  const handleGroupChange = async (groupId) => {
    setFilterGroup(groupId);
    setAdherents([]);
    setTableData([]);

    if (groupId) {
      try {
        const adherentsData = await getAdherentsByGroup(groupId);
        setAdherents(adherentsData);
      } catch (error) {
        console.error("Erreur lors du chargement des adhérents :", error);
      }
    }
  };

  const handleFilter = () => {
    if (!filterGroup || !selectedMonth) {
      alert("Veuillez sélectionner un groupe et un mois !");
      return;
    }

    setLoading(true);

    // Filtrer les présences par mois et groupe
    const filteredAttendances = attendances.filter((attendance) => {
      const date = new Date(attendance.date_presence);
      const month = format(date, "yyyy-MM");
      return month === selectedMonth;
    });

    // Construire les données du tableau
    const attendanceMap = filteredAttendances.reduce((acc, attendance) => {
      const day = format(new Date(attendance.date_presence), "dd");
      if (!acc[attendance.adherent_id]) {
        acc[attendance.adherent_id] = { dates: {}, total: 0 };
      }
      acc[attendance.adherent_id].dates[day] = attendance.presence;
      acc[attendance.adherent_id].total += attendance.presence ? 1 : 0;
      return acc;
    }, {});

    const tableRows = adherents.map((adherent) => ({
      id: adherent.id,
      nom: adherent.nom,
      prenom: adherent.prenom,
      dates: attendanceMap[adherent.id]?.dates || {},
      total: attendanceMap[adherent.id]?.total || 0,
    }));

    setTableData(tableRows);
    setLoading(false);
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ENSEIGNANTS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Tableau de Présences
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="mx-auto w-full p-4 mb-4 max-w-[1280px]">
        {/* Filtres */}
        <div className="flex flex-col lg:flex-row lg:items-left lg:justify-start space-y-4 lg:space-y-0 lg:gap-4 mb-4">
          <select
            className="select select-bordered"
            onChange={(e) => handleGroupChange(e.target.value)}
            value={filterGroup}
          >
            <option value="">Sélectionnez un groupe</option>
            {groups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.nom}
              </option>
            ))}
          </select>

          <select
            className="select select-bordered"
            onChange={(e) => setSelectedMonth(e.target.value)}
            value={selectedMonth}
          >
            <option value="">Sélectionnez un mois</option>
            {seasonMonths.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>
          <Button
            text="Afficher"
            onClick={handleFilter}
            className="btn btn-primary"
            disabled={loading || !filterGroup || !selectedMonth}
          />
        </div>

        {/* Tableau */}
        <div className="overflow-x-auto w-full max-w-[1280px] mx-auto">
          <table className="table table-auto w-full border-collapse border border-gray-300 min-w-full">
            <thead className="bg-gray-100 sticky top-0">
              <tr>
                <th className="border border-gray-300 px-1 py-1 bg-white sticky left-0 z-10">
                  Nom Prénom
                </th>
                {Array.from({ length: 31 }, (_, i) => (
                  <th
                    key={i}
                    className="border border-gray-300 px-1 py-1 text-xs"
                  >
                    {String(i + 1).padStart(2, "0")}
                  </th>
                ))}
                <th className="border border-gray-300 px-1 py-1">Total</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row) => (
                <tr key={row.id}>
                  <td className="border border-gray-300 px-1 py-1 bg-white sticky left-0 z-10">
                    {row.nom} {row.prenom}
                  </td>
                  {Array.from({ length: 31 }, (_, i) => {
                    const day = String(i + 1).padStart(2, "0");
                    const presence = row.dates[day];
                    return (
                      <td
                        key={day}
                        className={`border border-gray-300 px-1 py-1 ${
                          presence === true
                            ? "bg-green-500"
                            : presence === false
                            ? "bg-red-500"
                            : ""
                        }`}
                      />
                    );
                  })}
                  <td className="border border-gray-300 px-1 py-1 text-center">
                    {row.total}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AttendanceTable;
